/*!
Theme Name: Around The Crown 10k v2.0
Theme URI: https://bellaworksweb.com/
Author: Bellaworksweb
Author URI: https://bellaworksweb.com/
Description: Description
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: bellaworks
Tags:

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.

bellaworks is based on Underscores http://underscores.me/, (C) 2012-2015 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal http://necolas.github.com/normalize.css/
*/

@import "normalize";
@import "bourbon/bourbon";
@import "modules/animate";
@import "variables";
@import '0_libs/index';
@import '1_utilities/index';
@import '2_base/index';
@import '3_layout/index';
@import '4_components/index';
@import "owlcarousel";
@import "custom";